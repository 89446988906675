import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Profile.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";

export default function Profile({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  services,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  return (
    <div className="profile">
      <div className="profile_topnav">Profil</div>
      <div className="profile_body">
        <div className="profile_body_bio">
          <div className="profile_body_bio_left"></div>
          <div className="profile_body_bio_right">John Melcovic</div>
        </div>
        <div className="profile_body_services">
          {services.map((template) => (
            <div className="profile_body_listelement">
              <div className="profile_body_listelement_top">
                <video
                  key={template.videoURL} // Add key prop here
                  controls={true}
                  className=""
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  preload="metadata"
                  id={"video" + template.videoURL}
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                  loop={true}>
                  <source
                    key={template.videoURL + "src"}
                    src={template.videoURL}
                    type={`video/mp4`}
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="profile_body_listelement_bottom">
                {template.title}
              </div>
            </div>
          ))}
          {templates.map((template) => (
            <div
              className="profile_body_listelement"
              onClick={() => navigate("/editservice/" + template.id)}>
              <div className="profile_body_listelement_top">+</div>
              <div className="profile_body_listelement_bottom">
                {template.title}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Bottombar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
