import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";

export default function Home({ isMobile, apiURL, authToken, creators }) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  return (
    <div className="home">
      <div className="home_topnav">
        <div className="home_topnav_left">Filterbox</div>
        <div className="home_topnav_right">Menu</div>
      </div>
      <div className="home_body">
        {creators.map((creator) => (
          <div
            className="home_body_listelement"
            onClick={() => navigate("/influencer/" + creator.id)}>
            <div className="home_body_listelement_left">
              <img
                src={JSON.parse(creator.pictures)[0].url}
                className="home_body_listelement_left_img"
              />
            </div>
            <div className="home_body_listelement_middle">
              <div className="home_body_listelement_middle_toptext">
                {creator.companyname}
              </div>
              <div className="home_body_listelement_middle_bottomtext">
                300 Follower
              </div>
            </div>
            <div className="home_body_listelement_right">
              <div className="home_body_listelement_right_left">ab 50€</div>
              <div className="home_body_listelement_right_right">
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
      <Bottombar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
