import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home";
import * as authentication from "./functions/authentication";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Orders from "./screens/Orders";
import Profile from "./screens/Profile";
import ServiceEdit from "./screens/ServiceEdit";
import Influencer from "./screens/Influencer";
import Service from "./screens/Service";
import Create from "./screens/Create";
import Order from "./screens/DeliverStory";
import DeliverStory from "./screens/DeliverStory";
import Login from "./screens/Login";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.letsfluence.com";
  if (developerMode) {
    apiURL = "https://developercloud.letsfluence.com";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [reload, setReload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isMobile = useScreenType();

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
      setCompanyData([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
    setData: setCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: creators,
    loading: loadingCreatorData,
    error: errorCreatorData,
  } = useFetch(
    `${apiURL}/api/creators/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [reload] // Additional dependencies, if needed
  );

  const {
    data: templates,
    loading: loadingTemplates,
    error: errorTemplates,
  } = useFetch(
    `${apiURL}/api/templates/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: services,
    loading: loadingServices,
    error: errorServices,
    setData: setServices,
  } = useFetch(
    `${apiURL}/api/services/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: orders,
    loading: loadingOrders,
    error: errorOrders,
  } = useFetch(
    `${apiURL}/api/orders/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const templatesDummy = [
    {
      id: 1,
      title: "Sponsor in zwischen meines Videos",
      video: "Todays Sponsor is letsflunce,...,...",
    },
    {
      id: 2,
      title: "Unternehmen vorstellen",
      video:
        "Das ist das Unternehmen Bama Immobilien, die sind Immobilienmakler in Hamburg. Wer also mieten/vermieten, kaufen/verkaufen will, nehmt Kontakt auf. Link in meiner Bio",
    },
    {
      id: 3,
      title: "Neueröffnung promoten",
      video:
        "Leute aufgepasst, am 13.08 gibt es eine neue Sache: Einen Lieferdienst für deine Grillparty ! Einfach auf grillchef.app gehen und alles erhalten",
    },
    {
      id: 4,
      title: "Mitarbeiter gesucht",
      video: "Du sendest mir dein Design und ich programmiere die Website",
    },
    {
      id: 5,
      title: "Rabattakion",
      video: "Mit dem Code John34 kriegt ihr 34% auf Alles außer Tiernahrung",
    },
  ];

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                />
              }
            />
            <Route
              path="/influencer/:id"
              element={
                <Influencer
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  services={services}
                />
              }
            />
            <Route
              path="/influencer/:id/service/:serviceid"
              element={
                <Service
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  services={services}
                />
              }
            />

            <Route
              path="/profile"
              element={
                <Profile
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  services={services}
                />
              }
            />
            <Route
              path="/orders"
              element={
                <Orders
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  orders={orders}
                />
              }
            />
            <Route
              path="/editservice/:id"
              element={
                <ServiceEdit
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  services={services}
                  setServices={setServices}
                  reload={reload}
                  setReload={setReload}
                />
              }
            />
            <Route
              path="/create/:id"
              element={
                <Create
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  services={services}
                  setServices={setServices}
                  orders={orders}
                  reload={reload}
                  setReload={setReload}
                />
              }
            />
            <Route
              path="/deliverstory/:orderid"
              element={
                <DeliverStory
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  services={services}
                  setServices={setServices}
                  orders={orders}
                  reload={reload}
                  setReload={setReload}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  isMobile={isMobile}
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signUp={signUp}
                  username={username}
                  setUsername={setUsername}
                  signIn={signIn}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  companyData={companyData[0]}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
