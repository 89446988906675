import React, { useEffect, useRef, useState } from "react";
import "../styles/Home.css";
import "../styles/Camera.css";
import { isEmpty } from "../functions/isEmpty";

function Camera({
  onVideoRecorded,
  turnOffCam,
  recordStreams,
  setRecordStreams,
  overlayImageSrc, // Add a prop for the overlay image source
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [overlayImage, setOverlayImage] = useState(null);

  useEffect(() => {
    if (turnOffCam) {
      closeCamera();
    } else {
      startVideo();
    }
    return () => {
      closeCamera();
    };
  }, [turnOffCam]);

  useEffect(() => {
    // Load the overlay image
    if (overlayImageSrc) {
      const img = new Image();
      img.crossOrigin = "anonymous"; // Set the crossOrigin attribute
      img.src = overlayImageSrc;
      img.onload = () => {
        console.log("Overlay image loaded");
        setOverlayImage(img);
      };
      img.onerror = (err) =>
        console.error("Error loading overlay image: ", err);
    }
  }, [overlayImageSrc]);

  useEffect(() => {
    if (overlayImage) {
      drawOnCanvas();
    }
  }, [overlayImage]);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraActive(true);
        let helpArr = recordStreams;
        helpArr.push(stream);
        setRecordStreams(helpArr);
        setAudioStream(stream);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
        alert(
          "Error accessing the camera. Please ensure your browser settings allow camera and microphone access and the website is served over HTTPS."
        );
      });
  };

  const drawOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const video = videoRef.current;

    const drawFrame = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.save();
        ctx.scale(-1, 1); // Mirror horizontally for the video
        ctx.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);

        // Adjust the scale to un-mirror for the overlay image
        ctx.scale(-1, 1);

        if (overlayImage) {
          if (!isEmpty(overlayImage)) {
            const aspectRatio = overlayImage.width / overlayImage.height;
            const fixedWidth = 200; // Example fixed width
            const calculatedHeight = fixedWidth / aspectRatio;

            ctx.drawImage(overlayImage, 0, 50, fixedWidth, calculatedHeight);
          }
        }
        ctx.restore();
      }
      requestAnimationFrame(drawFrame);
    };
    drawFrame();
  };

  const startRecording = () => {
    const canvas = canvasRef.current;

    // Function to check if the device is iOS
    const isIOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    try {
      const videoStream = canvas.captureStream();
      const combinedStream = new MediaStream();

      // Add video track from canvas
      videoStream
        .getVideoTracks()
        .forEach((track) => combinedStream.addTrack(track));

      // Add audio track from original media stream
      if (audioStream) {
        audioStream
          .getAudioTracks()
          .forEach((track) => combinedStream.addTrack(track));
      }

      // Set mimeType based on device type
      const options = { mimeType: isIOS() ? "video/mp4" : "video/webm" };
      mediaRecorderRef.current = new MediaRecorder(combinedStream, options);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const blob = new Blob([event.data], { type: options.mimeType });
          const url = URL.createObjectURL(blob);

          const file = new File(
            [blob],
            `video.${options.mimeType.split("/")[1]}`,
            { type: options.mimeType }
          );
          const localURL = URL.createObjectURL(file);
          const filetype = file.type;

          let videoObject = {
            localURL: localURL,
            files: file,
            filetype: filetype,
          };

          setRecordedVideoURL(url); // Save the URL to state for download button
          onVideoRecorded(videoObject); // Pass the URL to the parent component
        } else {
          alert("Empty blob recorded.");
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording: ", error);
      alert(
        "Failed to start recording. Canvas might be tainted due to cross-origin content."
      );
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      alert("No recording to stop.");
    }
  };

  const handleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const downloadVideo = () => {
    if (recordedVideoURL) {
      const a = document.createElement("a");
      a.href = recordedVideoURL;
      a.download = "recorded-video.mp4";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      alert("No recorded video available to download.");
    }
  };

  const closeCamera = () => {
    let video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      setCameraActive(false);
      setRecordedVideoURL(null); // Clear recorded video URL on camera close
    }
  };

  return (
    <div className={cameraActive ? "camera" : "camera_off"}>
      {cameraActive && (
        <img
          src={overlayImageSrc}
          style={{
            width: "200px",
            height: "auto",
            marginTop: "50px",
            zIndex:
              "9999999999999999999999999999999999999999999999999999999999",
            position: "absolute",
            top: "0",
            left: "0",
          }}
        />
      )}
      <div className="camera-container">
        <video
          ref={videoRef}
          playsInline
          muted
          className={
            cameraActive ? "camera-video" : "camera-video_off"
          }></video>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>{" "}
        {!cameraActive && recordStreams.length > 0 && (
          <div className="no-camera-access" onClick={startVideo}>
            Du hast deine Kamera nicht freigegeben. Ändere dies in den
            Einstellungen deines Browsers.
          </div>
        )}
      </div>
      {recordStreams.length > 0 && (
        <div className="camera_div_bottom">
          <div
            className="camera_div_bottom_takePhoto"
            onClick={handleRecording}>
            <div className="camera_div_bottom_takePhoto_inner">
              {isRecording ? "Stop" : "Record"}
            </div>
          </div>
          {recordedVideoURL && (
            <div className="camera_div_bottom_download" onClick={downloadVideo}>
              Download Video
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Camera;

/* Working, but has no image function

import React, { useEffect, useRef, useState } from "react";
import "../styles/Home.css";
import "../styles/Camera.css";
import { isEmpty } from "../functions/isEmpty";

function Camera({
  onVideoRecorded,
  turnOffCam,
  recordStreams,
  setRecordStreams,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const [audioStream, setAudioStream] = useState(null);

  useEffect(() => {
    if (turnOffCam) {
      closeCamera();
    } else {
      startVideo();
    }
    return () => {
      closeCamera();
    };
  }, [turnOffCam]);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraActive(true);
        let helpArr = recordStreams;
        helpArr.push(stream);
        setRecordStreams(helpArr);
        setAudioStream(stream);
        // Start drawing video on canvas to achieve the mirrored effect
        drawOnCanvas();
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
        alert(
          "Error accessing the camera. Please ensure your browser settings allow camera and microphone access and the website is served over HTTPS."
        );
      });
  };

  const drawOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const video = videoRef.current;

    const drawFrame = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        //  canvas.width = video.videoWidth;
        //  canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.save();
        ctx.scale(-1, 1); // Mirror horizontally
        ctx.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);
        ctx.restore();
      }
      requestAnimationFrame(drawFrame);
    };
    drawFrame();
  };

  const startRecording = () => {
    const canvas = canvasRef.current;
    const videoStream = canvas.captureStream();
    const combinedStream = new MediaStream();

    // Add video track from canvas
    videoStream
      .getVideoTracks()
      .forEach((track) => combinedStream.addTrack(track));

    // Add audio track from original media stream
    if (audioStream) {
      audioStream
        .getAudioTracks()
        .forEach((track) => combinedStream.addTrack(track));
    }

    if (!combinedStream) {
      console.error("No video stream available.");
      return;
    }

    const options = { mimeType: "video/mp4" }; // Set mimeType to mp4 for iOS compatibility
    mediaRecorderRef.current = new MediaRecorder(combinedStream, options);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        const blob = new Blob([event.data], { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        setRecordedVideoURL(url); // Save the URL to state for download button
        onVideoRecorded(url); // Pass the URL to the parent component
      } else {
        alert("Empty blob recorded.");
      }
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
    startVideo();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      alert("No recording to stop.");
    }
  };

  const handleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const downloadVideo = () => {
    if (recordedVideoURL) {
      const a = document.createElement("a");
      a.href = recordedVideoURL;
      a.download = "recorded-video.mp4";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      alert("No recorded video available to download.");
    }
  };

  const closeCamera = () => {
    let video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      setCameraActive(false);
      setRecordedVideoURL(null); // Clear recorded video URL on camera close
    }
  };

  return (
    <div className="camera">
      <div className="camera-container">
        <video
          ref={videoRef}
          playsInline
          muted
          style={{
            transform: "scaleX(-1)",
            height: "100%",
            width: "100vw",
            objectFit: "cover",
          }} // Mirror the video element for playback
        ></video>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>{" "}
        {!cameraActive && !isEmpty(recordStreams) && (
          <div className="no-camera-access" onClick={startVideo}>
            Du hast deine Kamera nicht freigegeben. Ändere dies in den
            Einstellungen deines Browsers.
          </div>
        )}
      </div>
      {!isEmpty(recordStreams) && (
        <div className="camera_div_bottom">
          <div
            className="camera_div_bottom_takePhoto"
            onClick={handleRecording}>
            <div className="camera_div_bottom_takePhoto_inner">
              {isRecording ? "Stop" : ""}
            </div>
          </div>
          {recordedVideoURL && (
            <div className="camera_div_bottom_download" onClick={downloadVideo}>
              Download Video
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Camera;

*/

/* WORKING BUT RECORDED VIDEO NOT MIRRORING: TRY CANVAS 
import React, { useEffect, useRef, useState } from "react";
import "../styles/Home.css";
import "../styles/Camera.css";

function Camera({
  onVideoRecorded,
  turnOffCam,
  recordStreams,
  setRecordStreams,
}) {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);

  useEffect(() => {
    if (turnOffCam) {
      closeCamera();
    } else {
      startVideo();
    }
    return () => {
      closeCamera();
    };
  }, [turnOffCam]);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraActive(true);
        let helpArr = recordStreams;
        helpArr.push(stream);
        setRecordStreams(helpArr);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
        alert(
          "Error accessing the camera. Please ensure your browser settings allow camera and microphone access and the website is served over HTTPS."
        );
      });
  };

  const startRecording = () => {
    const video = videoRef.current;
    const stream = video.srcObject;

    if (!stream) {
      console.error("No video stream available.");
      return;
    }

    const options = { mimeType: "video/mp4" }; // Set mimeType to mp4 for iOS compatibility
    mediaRecorderRef.current = new MediaRecorder(stream, options);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        const blob = new Blob([event.data], { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        onVideoRecorded(url); // Save the URL to state for download button
      } else {
        alert("Empty blob recorded.");
      }
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
    startVideo();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      alert("No recording to stop.");
    }
  };

  const handleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const downloadVideo = () => {
    if (recordedVideoURL) {
      const a = document.createElement("a");
      a.href = recordedVideoURL;
      a.download = "recorded-video.mp4";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      alert("No recorded video available to download.");
    }
  };

  const closeCamera = () => {
    let video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      setCameraActive(false);
      setRecordedVideoURL(null); // Clear recorded video URL on camera close
    }
  };

  return (
    <div className="camera">
      <div className="camera-container">
        <video
          style={{ transform: "scaleX(-1)" }}
          ref={videoRef}
          playsInline={true}
          muted></video>
        {!cameraActive && (
          <div className="no-camera-access" onClick={startVideo}>
            Du hast deine Kamera nicht freigegeben. Ändere dies in den
            Einstellungen deines Browsers.
          </div>
        )}
      </div>
      <div className="camera_div_bottom">
        <div className="camera_div_bottom_takePhoto" onClick={handleRecording}>
          <div className="camera_div_bottom_takePhoto_inner">
            {isRecording ? "Stop" : "Record"}
          </div>
        </div>
        {recordedVideoURL && (
          <div className="camera_div_bottom_download" onClick={downloadVideo}>
            Download Video
          </div>
        )}
      </div>
    </div>
  );
}

export default Camera;


*/

/* NOT WORKING - NO RECORDED VIDEO
import React, { useRef, useState, useEffect } from "react";
import RecordRTC from "recordrtc";
import "../styles/Home.css";
import "../styles/Camera.css";

function Camera({ onVideoRecorded }) {
  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    getVideo();
    return () => {
      closeCamera();
    };
  }, []);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true }) // Include audio in getUserMedia constraints
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraActive(true);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
        alert(
          "Error accessing the camera. Please ensure your browser settings allow camera and microphone access and the website is served over HTTPS."
        );
      });
  };

  const startRecording = () => {
    let video = videoRef.current;
    const stream = video.srcObject;

    recorderRef.current = new RecordRTC(stream, {
      type: "video",
      mimeType: isIOS() ? "video/mp4" : "video/webm",
      disableLogs: true,
    });

    recorderRef.current.startRecording();
    setIsRecording(true);
  };

  const stopRecording = () => {
    recorderRef.current.stopRecording(() => {
      const blob = recorderRef.current.getBlob();
      const file = new File([blob], "video.mp4", { type: "video/mp4" });
      const localURL = URL.createObjectURL(file);
      const filetype = file.type;

      let videoObject = {
        localURL: localURL,
        files: file,
        filetype: filetype,
      };

      // Pass the object to the parent component
      onVideoRecorded(videoObject);
    });

    setIsRecording(false);
  };

  const handleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const closeCamera = () => {
    let video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      setCameraActive(false);
    }
  };

  return (
    <div className="camera">
      <div className="camera-container" onClick={getVideo}>
        <video ref={videoRef} playsInline={true} muted={true}></video>
        {!cameraActive && (
          <div className="no-camera-access" onClick={getVideo}>
            Du hast deine Kamera nicht freigegeben. Ändere dies in den
            Einstellungen deines Browsers.
          </div>
        )}
      </div>
      <div className="camera_div_bottom">
        <div className="camera_div_bottom_takePhoto" onClick={handleRecording}>
          <div className="camera_div_bottom_takePhoto_inner">
            {isRecording ? "Stop" : "Record"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Camera;
*/
