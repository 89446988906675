import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Orders.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";

export default function Orders({
  isMobile,
  apiURL,
  authToken,
  creators,
  orders,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  return (
    <div className="orders">
      <div className="orders_topnav">Aufträge</div>
      {orders.map((order) => (
        <div className="orders_body_listelement">
          <div className="orders_body_listelement_top">
            <div className="orders_body_listelement_top_left">
              <img
                src={order.input_logoURL}
                className="orders_body_listelement_top_left_img"
              />
            </div>
            <div className="orders_body_listelement_top_middle">
              <div className="orders_body_listelement_top_middle_toptext">
                {order.input_companyname}
              </div>
              <div className="orders_body_listelement_top_middle_bottomtext"></div>
            </div>
            <div className="orders_body_listelement_top_right">
              <div className="orders_body_listelement_top_right_left"></div>
              <div className="orders_body_listelement_top_right_right">
                {order.price + " €"}
              </div>
            </div>
          </div>
          <div className="orders_body_listelement_bottom">
            <div
              className="orders_body_listelement_bottom_row"
              onClick={() => navigate("/create/" + order.id)}>
              <div className="orders_body_listelement_bottom_row_left">
                srvcIMG
              </div>
              <div className="orders_body_listelement_bottom_row_middle">
                {order.title}
              </div>
              <div className="orders_body_listelement_bottom_row_right">
                {!isEmpty(order.delivery_content_url) ? (
                  <>
                    {" "}
                    <CheckIcon /> <ArrowForwardIcon />{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <CheckIconBlank /> <ArrowForwardIcon />{" "}
                  </>
                )}
              </div>
            </div>
            {!isEmpty(order.input_instastoryDate) && (
              <div
                className="orders_body_listelement_bottom_row"
                onClick={() => navigate("/deliverstory/" + order.id)}>
                <div className="orders_body_listelement_bottom_row_left">
                  srvcIMG
                </div>
                <div className="orders_body_listelement_bottom_row_middle">
                  Instagram Storypost
                </div>
                <div className="orders_body_listelement_bottom_row_right">
                  {!isEmpty(order.delivery_instagramstory_url) ? (
                    <>
                      {" "}
                      <CheckIcon /> <ArrowForwardIcon />{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <CheckIconBlank /> <ArrowForwardIcon />{" "}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <Bottombar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 18px;
  color: #474747;
`;

const CheckIconBlank = styled(MdCheckCircleOutline)`
  font-size: 18px;
  color: #474747;
`;
