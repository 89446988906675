import styled from "styled-components/macro";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForward,
  MdArrowForwardIos,
  MdAttachFile,
  MdBookmark,
  MdBookmarkBorder,
  MdDelete,
  MdDeleteOutline,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdPlayArrow,
  MdShoppingCart,
  MdUpload,
  MdUploadFile,
} from "react-icons/md";

import "../styles/EditService.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";
import Camera from "../components/Camera";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";

export default function Create({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  services,
  reload,
  setReload,
  orders,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [recordStreams, setRecordStreams] = useState([]);
  const [cameraActive, setCameraActive] = useState(false);
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeService, setActiveService] = useState({ title: "", video: "" });
  const [url, setURL] = useState("");
  const [editedpromptertext, setEditedpromptertext] = useState("");
  const [photo, setPhoto] = useState();
  const clickNext = () => {
    if (activeStep >= 2) {
      navigate("/profile");
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    if (!isEmpty(orders)) {
      orders.map((order) => {
        if (order.id == id) {
          setActiveService(order);
          setEditedpromptertext(replaceCompanyName(order.promptertext, order));
          if (!isEmpty(order.delivery_content_url)) {
            setURL(order.delivery_content_url);
            setCameraActive(false);
          } else {
            setCameraActive(true);
          }
        }
      });
    }
  }, [id, orders]);

  function replaceCompanyName(inputString, object) {
    // Check if the inputString contains "**companyname**"
    let newString = inputString;

    if (newString.includes("**companyname**")) {
      // Replace all occurrences of "**companyname**" with the value of activeService.input_companyname
      newString = newString.replace(
        /\*\*companyname\*\*/g,
        object.input_companyname
      );
    }
    if (newString.includes("**services**")) {
      // Replace all occurrences of "**companyname**" with the value of activeService.input_companyname
      newString = newString.replace(/\*\*services\*\*/g, object.input_services);
    }
    if (newString.includes("**position**")) {
      // Replace all occurrences of "**companyname**" with the value of activeService.input_companyname
      newString = newString.replace(
        /\*\*position\*\*/g,
        object.input_hiringposition
      );
    }
    // If "**companyname**" is not found, return the original inputString
    return newString;
  }

  const onPhotoTaken = (photoURL) => {
    if (!isEmpty(photoURL)) {
      setPhoto(photoURL);
    }
  };

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setCameraActive(true);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
        alert(
          "Error accessing the camera. Please ensure your browser settings allow camera access and the website is served over HTTPS."
        );
      });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (!isEmpty(photo)) {
      setURL(photo.localURL);
      setCameraActive(false);
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    }

    return () => {
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    };
  }, [photo]);

  const downloadVideo = () => {
    if (url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = "recorded-video.mp4";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      alert("No recorded video available to download.");
    }
  };

  // upload image file
  const handleUploadForImage = async () => {
    setIsLoading(true);
    const formData = new FormData();

    if (!isEmpty(photo)) {
      formData.append("files[]", photo.files); // Ensure the photo is appended with the correct key
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    }
  };

  // upload profile data
  const handleUpload = async (imgURL) => {
    setIsLoading(true);

    if (imgURL != "") {
      const resa = await fetch(`${apiURL}/api/orders/index.php?id=` + id, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          delivery_content_url: imgURL,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  // redirect
  const uploadEnds = (value) => {
    if (value != "") {
      setReload(!reload);
    }
    navigate("/orders");
    setIsLoading(false);
  };

  const resetCamera = () => {
    setCameraActive(true);
    setRecordStreams([]);
    setURL("");
  };

  return (
    <div className="editservice">
      <div className="editservice_topnav">
        <div
          className="editservice_topnav_back"
          onClick={() => navigate("/orders")}>
          <ArrowBackIcon /> Zurück
        </div>
      </div>
      <div className="editservice_body">
        {!isEmpty(url) ? (
          <>
            <video
              key={url} // Add key prop here
              controls={false}
              className="editservice_body_videorecorder"
              preload="metadata"
              id={"video" + url}
              autoPlay={true}
              playsInline={true}
              muted={false}
              loop={true}>
              <source key={url + "src"} src={url} type={`video/mp4`} />
              Your browser does not support the video tag.
            </video>
          </>
        ) : (
          <></>
        )}

        {cameraActive && (
          <div className="editservice_body_description">
            {editedpromptertext}
          </div>
        )}

        <Camera
          onVideoRecorded={onPhotoTaken}
          turnOffCam={!cameraActive}
          recordStreams={recordStreams}
          setRecordStreams={setRecordStreams}
          overlayImageSrc={
            isEmpty(activeService.input_logoURL)
              ? ""
              : activeService.input_logoURL
          }
        />

        <div className="editservice_body_navbar">
          <div className="" onClick={() => resetCamera()}>
            Neu aufnehmen
          </div>
          {isLoading ? (
            <div className="">...Loading</div>
          ) : (
            <div className="" onClick={() => handleUploadForImage()}>
              Speichern
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
/*
ich brauch felder fürs instagram sharen

*/

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const NextIcon = styled(MdArrowForward)`
  font-size: 18px;
  color: #ffffff;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #ffffff;
`;

const PlayArrowIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #ffffff;
`;

const DeleteIcon = styled(MdDeleteOutline)`
  font-size: 26px;
  color: #ffffff;
`;

const UploadIcon = styled(MdAttachFile)`
  font-size: 26px;
  color: #ffffff;
`;
